@tailwind base;
@tailwind components;
@tailwind utilities;

  .hoverEffect:hover img {
    opacity: 0.7; 
    transition: all .3s ease-in-out;
  }
  
  .absolute-overlay{
    transition: all .3s ease-in-out;
  }

  .hover-zoom {
    overflow: hidden;
  }
  
  .hover-zoom img {
    transition: transform 0.3s ease;
  }
  
  .hover-zoom:hover img {
    transform: scale(1.1);
  }

  @layer  {
    .dashboard-link {
      @apply flex justify-start items-center gap-2 py-3.5 px-2 cursor-pointer hover:bg-orange-100;
    }
  }